import React, { useState } from "react";
import {
  Box,
  Select,
  Input,
  Text,
  HStack,
  VStack,
  useMediaQuery,
  Flex,
  useToast,
  FormControl,
  FormHelperText,
} from "@chakra-ui/react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { BsFillCircleFill } from "react-icons/bs";
import { updateCase } from "../state/Reducers/newCaseReducer";

const NewCaseDetails = ({ setIndex, isClicked, setIsClicked }) => {
  const toast = useToast();
  const [ifSmallScreen] = useMediaQuery("(max-width:1920px)");
  const [error, setError] = useState(false);
  const [errorHosp, setErrorHosp] = useState(false);
  const [errorHelpNo, setErrorHelpNo] = useState(false);
  const dispatch = useDispatch();
  const { caseDetails } = useSelector((state) => state.newCaseState);
  const regex = /^[a-zA-Z]+$/;
  const handleCaseDetails = (e) => {
    if (e.target.name === "clinician") {
      if (!regex.test(e.target.value)) {
        // toast({
        //   title: "error",
        //   description: `Numbers, Emojis, and Special characters should not be allowed.`,
        //   status: "error",
        //   duration: 1000,
        //   isClosable: true,
        // });
        setError(true);
      } else {
        setError(false);
      }
    }
    if (e.target.name === "hospital") {
      if (!regex.test(e.target.value)) {
        // toast({
        //   title: "error",
        //   description: `Numbers, Emojis, and Special characters should not be allowed.`,
        //   status: "error",
        //   duration: 1000,
        //   isClosable: true,
        // });
        setErrorHosp(true);
      } else {
        setErrorHosp(false);
      }
    }
    if (e.target.name === "helplineNumber") {
      if (e.target.value.length < 15) {
        // toast({
        //   title: "error",
        //   description: `Numbers, Emojis, and Special characters should not be allowed.`,
        //   status: "error",
        //   duration: 1000,
        //   isClosable: true,
        // });
        setErrorHelpNo(true);
      } else {
        setErrorHelpNo(false);
      }
    }
    // console.log("name", e.target.name, e.target.value);
    dispatch(updateCase({ name: e.target.name, value: e.target.value }));
  };
  const handleCheckbox = (e) => {
    const urgency = e.target.checked ? "urgent" : "normal";
    dispatch(updateCase({ name: e.target.name, value: urgency }));
  };

  return (
    <Box
      fontFamily="inter"
      fontSize={ifSmallScreen ? "14px" : " 0.72916vw"}
      mt="2vh"
      zIndex="0"
    >
      <Box>
        <Flex w="100%" justifyContent="center" mb="10px">
          <HStack spacing="26px">
            <BsFillCircleFill
              size={17}
              color="rgba(27, 117, 188, 0.43"
              style={{ cursor: "pointer" }}
            />
            <BsFillCircleFill
              size={17}
              color="#D9D9D9"
              style={{ cursor: "pointer" }}
              onClick={() => setIndex(1)}
            />
            <BsFillCircleFill
              size={17}
              color="#D9D9D9"
              style={{ cursor: "pointer" }}
              onClick={() => setIndex(2)}
            />
          </HStack>
        </Flex>
        <Text fontSize={ifSmallScreen ? "14px" : "20px"} mb="2vh">
          Case Details
        </Text>
        <HStack mb="2.592vh" justifyContent="space-between">
          <FormControl>
            <VStack align="left">
              <Text htmlFor="helpline_number" color="#000">
                Case ID*
              </Text>
              <Input
                id="caseId"
                name="caseId"
                w="26vw"
                h="3.055vh"
                minH="33px"
                value={caseDetails.caseId}
                placeholder="Enter text here"
                borderRadius={5}
                border="1px solid #DEDEDE"
                resize="none"
                onChange={(e) => handleCaseDetails(e)}
                maxLength="20"
              />
              {!caseDetails.caseId ? (
                <FormHelperText>Case ID is required</FormHelperText>
              ) : null}
            </VStack>
          </FormControl>

          <VStack align="left">
            <Text htmlFor="hospital" color="#000">
              Hospital
            </Text>
            <Input
              id="hospital"
              name="hospital"
              value={caseDetails.hospital}
              w="26vw"
              h="3.055vh"
              minH="33px"
              placeholder="Enter text here"
              borderRadius={5}
              border="1px solid #DEDEDE"
              resize="none"
              onChange={(e) => handleCaseDetails(e)}
              maxLength="46"
            />
          </VStack>
        </HStack>
        <HStack mb="2.592vh" justifyContent="space-between">
          <VStack align="left">
            <Text htmlFor="department_from" color="#000">
              Department from
            </Text>
            <Input
              id="department_from"
              name="departmentFrom"
              w="26vw"
              h="3.055vh"
              minH="33px"
              color="light.400"
              borderRadius={5}
              border="1px solid #DEDEDE"
              value={caseDetails.departmentFrom}
              onChange={(e) => handleCaseDetails(e)}
              maxLength="46"
            />
          </VStack>
          <VStack align="left">
            <Text htmlFor="department_to" color="#000">
              Department to
            </Text>
            <Select
              id="department_to"
              name="departmentTo"
              w="26vw"
              h="3.055vh"
              minH="33px"
              color="light.400"
              borderRadius={5}
              border="1px solid #DEDEDE"
              value={caseDetails.departmentTo}
              onChange={(e) => handleCaseDetails(e)}
            >
              <option hidden disabled value="">
                Select option
              </option>
              <option
                value="cytopathology"
                disabled={caseDetails.departmentFrom === "cytopathology"}
              >
                Cytopathology
              </option>
              <option
                value="hematopathology"
                disabled={caseDetails.departmentFrom === "hematopathology"}
              >
                Hematopathology
              </option>
              <option
                value="histopathology"
                disabled={caseDetails.departmentFrom === "histopathology"}
              >
                Histopathology
              </option>
            </Select>
          </VStack>
        </HStack>

        <HStack mb="2.592vh" justifyContent="space-between">
          <VStack align="left">
            <Text htmlFor="clinician" color="#000">
              Clinician
            </Text>
            <Input
              id="clinician"
              name="clinician"
              type="text"
              w="26vw"
              h="3.055vh"
              minH="33px"
              value={caseDetails.clinician}
              placeholder="Enter text here"
              borderRadius={5}
              border="1px solid #DEDEDE"
              resize="none"
              onChange={(e) => handleCaseDetails(e)}
              maxLength="35"
            />
          </VStack>

          <VStack align="left">
            <Text htmlFor="helpline_number" color="#000">
              Helpline Number
            </Text>
            <PhoneInput
              country="us"
              inputProps={{
                name: "helplineNumber",
                required: true,
                autoFocus: true,
                id: "helpline_number",
              }}
              enableSearch="true"
              placeholder="Enter phone number"
              value={caseDetails.helplineNumber}
              onChange={(country, formattedValue, value, e) => {
                dispatch(
                  updateCase({
                    name: "helplineNumber",
                    value: e,
                  })
                );
              }}
              style={{
                width: "26vw",
                height: "3.055vh",
                minHeight: "33px",
              }}
              buttonStyle={{ height: "3.6vh", minHeight: "33px" }}
              inputStyle={{ width: "26vw" }}
            />
          </VStack>
        </HStack>
        <HStack mb="2.592vh" justifyContent="space-between">
          <VStack align="left">
            <Text htmlFor="organ" color="#000">
              Organ
            </Text>
            <Select
              id="organ"
              name="organ"
              borderRadius={5}
              border="1px solid #DEDEDE"
              w="26vw"
              h="3.055vh"
              minH="33px"
              color="light.400"
              placement="bottom"
              value={caseDetails.organ}
              onChange={(e) => handleCaseDetails(e)}
            >
              <option hidden disabled value="">
                Select option
              </option>
              <option value="adipose-tissue">Fat (adipose tissue)</option>
              <option value="adrenal-glands">Adrenal glands</option>
              <option value="anus">Anus</option>
              <option value="bladder">Bladder</option>
              <option value="blood-vessels">Blood vessels</option>
              <option value="bones">Bones</option>
              <option value="brain">Brain</option>
              <option value="breast">Breast</option>
              <option value="bronchi">Bronchi</option>
              <option value="cartilage">Cartilage</option>
              <option value="diaphragm">Diaphragm</option>
              <option value="ears">Ears</option>
              <option value="eyes">Eyes</option>
              <option value="fallopian-tubes">Fallopian tubes</option>
              <option value="gallbladder">Gallbladder</option>
              <option value="hair">Hair</option>
              <option value="heart">Heart</option>
              <option value="joints">Joints</option>
              <option value="kidneys">Kidneys</option>
              <option value="large-intestine">Large intestine</option>
              <option value="liver">Liver</option>
              <option value="lungs">Lungs</option>
              <option value="lymph-nodes">Lymph nodes</option>
              <option value="lymphatic-vessels">Lymphatic vessels</option>
              <option value="mammary-glands">
                Mammary glands (in females)
              </option>
              <option value="muscles">Muscles</option>
              <option value="nails">Nails</option>
              <option value="nerves">Nerves</option>
              <option value="nose">Nose</option>
              <option value="ovaries">Ovaries</option>
              <option value="pancreas">Pancreas</option>
              <option value="penis">Penis</option>
              <option value="pituitary-gland">Pituitary gland</option>
              <option value="prostate-gland">Prostate gland</option>
              <option value="rectum">Rectum</option>
              <option value="salivary-glands">Salivary glands</option>
              <option value="semen">Seminal vesicles</option>
              <option value="skin">Skin</option>
              <option value="small-intestine">Small intestine</option>
              <option value="spinal-cord">Spinal cord</option>
              <option value="spleen">Spleen</option>
              <option value="stomach">Stomach</option>
              <option value="sweat-glands">Sweat glands</option>
              <option value="tendons">Tendons</option>
              <option value="testes">Testes</option>
              <option value="thymus">Thymus</option>
              <option value="thymus-gland">Thymus gland</option>
              <option value="thyroid-gland">Thyroid gland</option>
              <option value="tongue">Tongue</option>
              <option value="trachea">Trachea</option>
              <option value="ureters">Ureters</option>
              <option value="urethra">Urethra</option>
              <option value="uterus">Uterus</option>
              <option value="vagina">Vagina</option>
              <option value="vagina">Vagina</option>
            </Select>
          </VStack>

          <VStack align="left">
            <Text htmlFor="specimen_size" color="#000">
              Specimen Size
            </Text>
            <Select
              id="specimen_size"
              name="specimenSize"
              w="26vw"
              h="3.055vh"
              minH="33px"
              borderRadius={5}
              border="1px solid #DEDEDE"
              color="light.400"
              value={caseDetails.specimenSize}
              onChange={(e) => handleCaseDetails(e)}
            >
              <option hidden disabled value="">
                Select option
              </option>
              <option value="large">Large</option>
              <option value="medium">Medium</option>
              <option value="small">Small</option>
            </Select>
          </VStack>
        </HStack>

        <HStack>
          <VStack align="left">
            <Text htmlFor="specimen_size" color="#000">
              Turn around time
            </Text>
            <Select
              id="turn_around_time"
              name="turnAroundTime"
              borderRadius={5}
              border="1px solid #DEDEDE"
              w="26vw"
              h="3.055vh"
              minH="33px"
              color="light.400"
              value={caseDetails.turnAroundTime}
              onChange={(e) => handleCaseDetails(e)}
            >
              <option hidden disabled value="">
                Select option
              </option>
              <option value="1 day">1 day</option>
              <option value="2 day">2 day</option>
              <option value="3 day">3 day</option>
              <option value="4 day">4 day</option>
              <option value="1 week">1 week</option>
              <option value="1 week+">1 week+</option>
            </Select>
          </VStack>
        </HStack>

        <HStack />
        <HStack pt="1vw">
          <input
            type="checkbox"
            name="urgentCase"
            onChange={handleCheckbox}
            id="urgent_case"
            value="urgent"
          />
          <Text fontSize={ifSmallScreen ? "12px" : " 0.72916vw"}>
            Mark it if this case is urgent
          </Text>
        </HStack>
      </Box>
    </Box>
  );
};

export default NewCaseDetails;
