import React, { useState } from "react";
import {
  Box,
  Select,
  Input,
  Text,
  HStack,
  VStack,
  useMediaQuery,
  Flex,
  Textarea,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { BsFillCircleFill } from "react-icons/bs";
import { updateCase } from "../state/Reducers/newCaseReducer";

const PatientDetails = ({ setIndex }) => {
  const { caseDetails } = useSelector((state) => state.newCaseState);
  const dispatch = useDispatch();
  const [ifSmallScreen] = useMediaQuery("(max-width:1920px)");
  const [error, setError] = useState(false);
  const [errorContact, setErrorContact] = useState(false);
  const regex = /^[a-zA-Z]+$/;
  const handleCaseDetails = (e) => {
    if (e.target.name === "patientName") {
      if (!regex.test(e.target.value)) {
        // toast({
        //   title: "error",
        //   description: `Numbers, Emojis, and Special characters should not be allowed.`,
        //   status: "error",
        //   duration: 1000,
        //   isClosable: true,
        // });
        setError(true);
      } else {
        setError(false);
      }
    }
    if (e.target.name === "patientContactNumber") {
      if (e.target.value.length < 13) {
        // toast({
        //   title: "error",
        //   description: `Numbers, Emojis, and Special characters should not be allowed.`,
        //   status: "error",
        //   duration: 1000,
        //   isClosable: true,
        // });
        setErrorContact(true);
      } else {
        setErrorContact(false);
      }
    }
    // console.log("name", e.target.name, e.target.value);
    dispatch(updateCase({ name: e.target.name, value: e.target.value }));
  };
  const handleKeyDown = (event) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };
  return (
    <Box
      fontFamily="inter"
      fontSize={ifSmallScreen ? "14px" : " 0.72916vw"}
      mt="2vh"
      zIndex="0"
    >
      <Box>
        <Flex w="100%" justifyContent="center" mb="10px">
          <HStack spacing="26px">
            <BsFillCircleFill
              size={17}
              color="#D9D9D9"
              style={{ cursor: "pointer" }}
              onClick={() => setIndex(0)}
            />
            <BsFillCircleFill
              size={17}
              color="rgba(27, 117, 188, 0.43"
              style={{ cursor: "pointer" }}
            />
            <BsFillCircleFill
              size={17}
              color="#D9D9D9"
              style={{ cursor: "pointer" }}
              onClick={() => setIndex(2)}
            />
          </HStack>
        </Flex>
        <Text fontSize={ifSmallScreen ? "14px" : "20px"} mb="2vh">
          Patient Details
        </Text>
        <HStack mb="2.592vh" justifyContent="space-between">
          <VStack align="left">
            <Text htmlFor="patient_name" color="#000">
              Patient Name
            </Text>
            <Input
              type="text"
              id="patient_name"
              name="patientName"
              value={caseDetails.patientName}
              onChange={(e) => handleCaseDetails(e)}
              w="26vw"
              h="3.055vh"
              minH="33px"
              placeholder="Enter text here"
              borderRadius={5}
              border="1px solid #DEDEDE"
              maxLength="35"
            />
          </VStack>

          <VStack align="left">
            <Text htmlFor="contact_number" color="#000">
              Contact Number
            </Text>
            <PhoneInput
              country="us"
              inputProps={{
                name: "patientContactNumber",
                required: true,
                autoFocus: true,
                id: "contact_number",
              }}
              enableSearch="true"
              placeholder="Enter phone number"
              value={caseDetails.patientContactNumber}
              onChange={(country, formattedValue, value, e) => {
                dispatch(
                  updateCase({
                    name: "patientContactNumber",
                    value: e,
                  })
                );
              }}
              style={{
                width: "26vw",
                height: "3.055vh",
                minHeight: "33px",
              }}
              buttonStyle={{ height: "3.6vh", minHeight: "33px" }}
              containerStyle={{
                border: "10px solid black",
              }}
              inputStyle={{ width: "26vw" }}
            />
          </VStack>
        </HStack>
        <HStack mb="2.592vh" justifyContent="space-between">
          <HStack maxW="30%">
            <VStack align="left">
              <Text htmlFor="age" color="#000">
                Date of Birth
              </Text>
              <Input
                type="date"
                id="age"
                name="patientDOB"
                value={caseDetails.patientDOB}
                onChange={(e) => handleCaseDetails(e)}
                max="9999-12-31"
                w="12.8vw"
                h="3.055vh"
                minH="33px"
                placeholder="Enter text here"
                borderRadius={5}
                border="1px solid #DEDEDE"
                onWheel={(e) => e.target.blur()}
                maxLength="2"
                required
              />
            </VStack>

            <VStack align="left">
              <Text htmlFor="age" color="#000">
                Gender
              </Text>
              <Select
                id="gender"
                name="patientGender"
                w="12.8vw"
                h="3.055vh"
                minH="33px"
                placeholder="Enter text here"
                borderRadius={5}
                border="1px solid #DEDEDE"
                value={caseDetails.patientGender}
                onChange={(e) => handleCaseDetails(e)}
                required
              >
                <option hidden disabled value="">
                  Select option
                </option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="non binary">Non Binary</option>
              </Select>
            </VStack>
          </HStack>

          <VStack align="left">
            <Text htmlFor="uhid_number" color="#000">
              UHID
            </Text>
            <Input
              id="uhid_number"
              name="uhid"
              w="26vw"
              h="3.055vh"
              minH="33px"
              value={caseDetails.uhid}
              top="0"
              placeholder="Enter text here"
              borderRadius={5}
              border="1px solid #DEDEDE"
              resize="none"
              onChange={(e) => handleCaseDetails(e)}
              maxLength="14"
              onKeyDown={handleKeyDown}
              required
            />
          </VStack>
        </HStack>
        <HStack
          mb="2.592vh"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <VStack align="left">
            <Text htmlFor="address" color="#000">
              Address
            </Text>
            <Textarea
              id="address"
              name="patientAddress"
              value={caseDetails.patientAddress}
              w="26vw"
              h="10vh"
              minH="33px"
              placeholder="Enter text here"
              borderRadius={5}
              border="1px solid #DEDEDE"
              resize="none"
              onChange={(e) => handleCaseDetails(e)}
              maxLength="55"
            />
          </VStack>
          <VStack align="left">
            <Text htmlFor="patient_description" color="#000">
              Additional Information
            </Text>
            <Textarea
              id="patient_description"
              name="patientDescription"
              w="26vw"
              h="10vh"
              minH="33px"
              // borderRadius={5}
              placeholder="Enter text here"
              borderRadius={5}
              border="1px solid #DEDEDE"
              resize="none"
              css={{
                "&::-webkit-scrollbar": {
                  width: "4px",
                },
                "&::-webkit-scrollbar-track": {
                  width: "6px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#C4C4C4",
                },
              }}
              value={caseDetails.patientDescription}
              onChange={(e) => handleCaseDetails(e)}
              maxLength="450"
            />
          </VStack>
        </HStack>
      </Box>
    </Box>
  );
};
export default PatientDetails;
